import { ref } from "vue";

export default function useOperacionalEdit() {
  const refOperacionalEditTable = ref(null);

  const muniColums = [
    { key: "select", label: "Selecionar", sortable: false },
    { key: "name", label: "Município", sortable: true },
    { key: "codeSerpro", label: "SERPRO", sortable: true },
    { key: "state", label: "UF", sortable: true },
  ];

  const noMuniColums = [
    { key: "select", label: "Selecionar", sortable: false },
    { key: "name", label: "Município", sortable: true },
    { key: "region", label: "Região Operacional", sortable: true },
    { key: "area", label: "Área Operacional", sortable: true },
    { key: "codeSerpro", label: "SERPRO", sortable: true },
    { key: "state", label: "UF", sortable: true },
  ];

  const totalGroup = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  return {
    refOperacionalEditTable,
    muniColums,
    noMuniColums,
    perPageOptions,
    totalGroup,
    currentPage,
    searchQuery,
    sortBy,
    isSortDirDesc,
  };
}
